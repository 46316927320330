


<div class="container-fluid" style="height: 830px;">
    <div class="row ">
        <div class="col-lg-6">
            <div class="card1">
                <div class="row  justify-content-center border-line wall-image-geogit-login"> <img src="assets/wall.svg" class="image"> </div>
            </div>
            <div class="centered-geogit-login">
              <div class="row left-geogit-login">
                  <img src="assets/geojit-logo.svg" title="Geojit-Credits" [routerLink]="['/']" alt="Geojit-Credits" class="logo-geogit-login" style="cursor: pointer;">
                  <h2 class="head-text-geogit-login" >Avail Loan Against<br>Shares online,<br> instantly.</h2>
                  <img src="assets/login-bottom.svg" class="bottom-image-geogit-login" alt="Avatar woman">
               </div>
             </div>
        </div>
        <div class="col-lg-5 box-center-geogit-login" style="max-width: 500px;">
            <div class="card2-geogit-login card border-0 ">
               <form *ngIf="!DoneBoxshow">
                  <div class="row col-sm-10">
                    <h1 class="text-top-geogit-login">Create PIN</h1>

                    </div>
                <div class="row col-sm-11"> 
                    
                  <label class="mb-1">
                    </label><img class="image-icon-geogit-login" src="assets/red-eye.svg" style="bottom: 42px;" /> <input  type="number"  class="mb-4 input-box-geojit-login"  name="email" placeholder="Enter PIN" > 
                      
              </div>
                <div class="row col-sm-11"> <label class="mb-1">
                    </label> <input  id="myPassword" name="password" placeholder="Confirm PIN" type="number" class="input-box-geojit-login">
                  </div>
            
                <div class="row  col-sm-11 mt-4"> <button type="submit"  class=" btn-blue-geogit-login text-center-geogit-login" (click)="Done()" style="max-width: 186px;">Done</button> </div>
              </form> 
              <form *ngIf="DoneBoxshow"   class="form-login-geogit-login ">
                <div class="row col-sm-11 place-content-geojit-signup ">
                    <img src="assets/done.svg" class="place-margin-geojit-signup">
                </div>
                <div class="row col-sm-11 place-content-geojit-signup">
                    <h2  class="mb-0  mt-2 text-success-geogit-signup">Success</h2>
                </div>
                 <div class="row col-sm-11  mb-4 text-success-geogit-signup mt-4"> <small class="font-weight-geogit-login">Your account has been created, Successfully.</small> </div>
                 
                 <div class="row  col-sm-11 mt-4"> <button type="submit"  class=" btn-blue-geogit-login text-center-geogit-login" [routerLink]="['/dash/home']">Continue</button> </div>
                 
         </form>  
            </div>
        </div>

    </div>
</div>