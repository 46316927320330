import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder }  from '@angular/forms';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
passview:boolean=false;
contactemail:any;
loginForm:FormGroup;
submitted = false;
OTPScreen:boolean=false;
NewPinScreen?:boolean=false;

  constructor( private formBuilder: FormBuilder) {
    console.log('hi');
    this.passview=false;
    
    this.loginForm = formBuilder.group({
      email: ['', Validators.required],
      password: ['', Validators.required],
    });

    console.log('username',this.loginForm.value.username);

   }

  ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(6)]]
  });
  }

  get f() { return this.loginForm.controls; }
onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.loginForm.invalid) {
        return;
    }
    
   
}

/////
   mouseoverPass() {
    this.passview=true;
    console.log('hi');
    
  }
   mouseoutPass() {
     this.passview=false;
     console.log('hello');

   
  }
  login()
  {
    console.log('username',this.loginForm.value.email,this.loginForm.value.password);
  }
  change(event:any)
  {
    console.log('username',this.loginForm.value.email,this.loginForm.value.password);
    console.log('username for',event.target.value);

  }
  forget()
  {
      this.OTPScreen=true;
      this.NewPinScreen=false;
    this.submitted = true;
    // stop here if form is invalid
   
  }
  VerifyOTP()
  {
    this.OTPScreen=false;
    this.NewPinScreen=true;

  }
 
}
