

  <!-- <div id="content" class="display-sidebar-geojit-sidebar">
    <nav class=" navbar-expand-lg navbar-light">
        <div class="container-fluid"> -->

            <!-- <button (click)="onActivate($event)" type="button" id="sidebarCollapse" class="btn" style="position: fixed;z-index: 3;color: #61c5b1;">
              <i class="fa fa-bars" ></i>

            </button> -->
            <!-- <button class="btn btn-dark d-inline-block d-lg-none ml-auto" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="true" aria-label="Toggle navigation">
                <i class="fas fa-align-justify"></i>
            </button> -->
        <!-- </div>
    </nav>
</div> -->






<div id="sidebar" class="sidebar sidebar-geojit-dashboard sideb"  ng-controller="HeaderController">
  <div >
    <ul class="list-unstyled components">
       
        <li style="padding-top: 18px;" (click)="hideSidebar()">
          <a (click)="onClick('/dash/home')"   [ngClass]="{'active': tab==='tab1'}" [routerLink]="['home']" class="active"  > 
            <img *ngIf="tab !=='tab1'" src="assets/sidebar/sidebar-home1.svg" class="sidebar-icons-geojit-sidebar" style="width: 24px;height: 24px;"/>

            <img    *ngIf="tab ==='tab1'" src="assets/sidebar/sidebar-home.svg" class="sidebar-icons-geojit-sidebar" style="width: 24px;height: 24px;" />Home</a>
        </li>
        <li>
          <a (click)="onClick('/dash/account')"   [ngClass]="{'active': tab==='tab2'}" [routerLink]="['account']"> <img *ngIf="tab==='tab2'" src="assets/sidebar/account-summary2.svg" class="sidebar-icons-geojit-sidebar" style="width: 19px;height: 19px;"/>
            
            <img *ngIf="tab !=='tab2'" src="assets/sidebar/account-summary.svg" class="sidebar-icons-geojit-sidebar" style="width: 19px;height: 19px;"/>
            Account Summary</a>
        </li>
        <li>
          <a (click)="onClick('/dash/my-activity')"   [ngClass]="{'active': tab==='tab3'}" [routerLink]="['my-activity']">
            
            <img *ngIf="tab !=='tab3'"  src="assets/sidebar/my-activities.svg" class="sidebar-icons-geojit-sidebar" />
            <img *ngIf="tab==='tab3'"  src="assets/sidebar/my-activities2.svg" class="sidebar-icons-geojit-sidebar" />My Activities</a>
        </li>
        <li>
          <a (click)="onClick('/dash/profile')"   [ngClass]="{'active': tab==='tab4'}" [routerLink]="['profile']" click="save()">
            <img *ngIf="tab !=='tab4'"   src="assets/sidebar/user-profile.svg" class="sidebar-icons-geojit-sidebar"  style="width: 16px;margin-right: 24px;"/>
            <img *ngIf="tab==='tab4'"   src="assets/sidebar/user-profile2.svg" class="sidebar-icons-geojit-sidebar"  style="width: 16px;margin-right: 24px;"/>User Profile</a>
        </li>

        <li>
          <a (click)="onClick('/dash/las')"   [ngClass]="{'active': tab==='tab6'}" [routerLink]="['las']" >
            <img *ngIf="tab !=='tab6'"  src="assets/sidebar/LAAS.svg" class="sidebar-icons-geojit-sidebar" style="width: 20px;height: 20px;"/>
            <img *ngIf="tab==='tab6'"  src="assets/sidebar/LAAS2.svg" class="sidebar-icons-geojit-sidebar" style="width: 20px;height: 20px;"/>LAS Eligible Security</a>
        </li>
        <li>
          <a (click)="onClick('/dash/help-security')"   [ngClass]="{'active': tab==='tab5'}" [routerLink]="['help-security']">
            <img *ngIf="tab !=='tab5'"  src="assets/sidebar/help.svg" class="sidebar-icons-geojit-sidebar" />
            <img *ngIf="tab==='tab5'"  src="assets/sidebar/help2.svg" class="sidebar-icons-geojit-sidebar" />Help And Support</a>
        </li>
    </ul>   
  </div>
  </div>
  <!-- <div  class=" sidebar-geojit-dashboard"> -->
    <!-- Sidebar  -->
    

    <!-- Page Content  -->
  
<!-- </div> -->
