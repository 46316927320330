import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './views/user/login/login.component';
import { SignupComponent } from './views/user/signup/signup.component';
import { HomePageComponent } from './views/dashboard/home-page/home-page.component';
import { DashboardLayoutComponent } from './views/dashboard/dashboard-layout/dashboard-layout.component';
import { CreatePinComponent } from './views/user/create-pin/create-pin.component';

const routes: Routes = [
  {
  path: 'login',
  component: LoginComponent,
},
{
  path: 'signup',
  component: SignupComponent,
},
{
  path: 'create-pin',
  component: CreatePinComponent,
},
{
  path: '',
  component: HomePageComponent,
},
{
  path: 'dash',
  component: DashboardLayoutComponent,
//   children: [
//     {
//   path: '',
//   loadChildren: 'app/views/home/dashboard-layout/dashboard-layout.module#DashboardLayoutModule'
// }]
children: [
      {
    path:'',
    loadChildren: () => import('./views/dashboard/dashboard-layout/dashboard-layout.module').then(m => m.DashboardLayoutModule)
      }]
}
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
