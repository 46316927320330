import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder,AbstractControl }  from '@angular/forms';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {
  passview:boolean=false;
  CreatePinBoxshow:boolean=false;
  DoneBoxshow:boolean=false;
  loginForm:FormGroup;
  showmobile?:number;
  contactemail:any;
  
  MobileAuth:boolean=false;
  EmailAuth:boolean=false;

  timeLeft: number = 60;
  interval:any;
  // mathFormGroup: FormGroup;
    constructor( )
    {
      this.loginForm = new FormGroup(
        {
          
          firstNumber: new FormControl(this.randomNumber(), [
            Validators.required
          ]),
          secondNumber: new FormControl(this.randomNumber(), [
            Validators.required
          ]),
          answer: new FormControl("")
        },
        [this.answerValidator]
      );
      

     
      console.log('hi');
      this.passview=false;
    //   this.loginForm = formBuilder.group({
    //   username: ['', Validators.required],
    //   password: ['', Validators.required],
    //   mobile: ['', Validators.required],
    // });
    }

    randomNumber = (min = 1, max = 99) => {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    };
    
    
    answerValidator(form: AbstractControl) {
      console.log(form.value);
      const { firstNumber, secondNumber, answer } = form.value;
      if (+answer === parseInt(firstNumber) + parseInt(secondNumber)) {
        return null;
      }
      return { math: true };
    }
  ngOnInit(): void 
  {
    console.log('ngomimit');
  }
  
  signup(){
    
  }
  verifyEmail()
  {
    this.showmobile=this.loginForm.value.mobile;
    this.EmailAuth=true;
  }
  verifyMobile(){
    this.MobileAuth=true;
  }
  change(event:any)
  {
    console.log('username',this.loginForm.value.username,this.loginForm.value.password);
    console.log('username for',event.target.value);
  }
  
  // reloadCaptcha(){

  //   console.log('reloadcaptcha');
  //   this.loginForm.value.firstNumber = '';
  //   this.loginForm.value.secondNumber = '';
  //   this.initForm();

  // }
  // initForm() {
  //   this.loginForm = new FormGroup(
  //     {
  //       firstNumber: new FormControl(this.randomNumber(), [
  //         Validators.required
  //       ]),
  //       secondNumber: new FormControl(this.randomNumber(), [
  //         Validators.required
  //       ]),
  //       answer: new FormControl("")
  //     },
  //     [this.answerValidator]
  //   );
  // }
  
 



}
