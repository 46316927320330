<div class=" navbar fixed-top-geogit-home ">
    <div class="container">     
        <img src="assets/geojit-logo.svg" class="header-logo-geogit-landing">
        <form class="form-inline my-2 my-lg-0 header-form-geogit-home">
            <button [routerLink]="['/signup']" class="  header-button-geogit-home " type="submit">Signup</button>
            <button [routerLink]="['/login']" class="  header-button-geogit-home header-margin-geogit-home" type="submit">Login</button>
        </form>
    </div>
</div>
<div style="position: absolute;top: 0px;bottom: 0px;left: 0px;right: 0px;overflow: auto;">
<div class=" main-secu-geojit-landing">
    <div class="col banner-wthree-info">
        <div class="container py-md-4">
                <h3>Loan <br>Against Shares<span class="fa fa-star star-icon-geojit-landing" style=" color: #0f887c;"></span></h3>
                <h6>Meet your ﬁnancial obligations <br> without selling your shares</h6>
                <button  class=" shop" type="submit">Apply Now</button>
        </div>
    </div>
</div>
<section class="banner-bottom  section-second-geojit-landing "  >
    <div class="section-firstback-geojit-landing d-flex">
    <div class="container py-md-4  ">
        <div class="row text-left  section-first-geojit-landing">
            <div class="col-md-6 content-first-geojit-landing ab-contents" >
                <div class="col">
                    <h4 class="image-desktop">Feature of loan against shares scheme<br> offered by Geojit Credits Pvt Ltd</h4>
                    <h4 class="image-mobile">Feature of loan against shares scheme offered by Geojit<br> Credits Pvt Ltd</h4>
                </div>
                <ul>
                    <li>Facility to apply online </li><p>Clients can apply and complete all documentation online</p>
                    <li> Easy documentation for onboarding</li>
                    <p>All that the clients have to do is sign the loan document and<br> pledge the securities in favour of Geojit Credits Pvt Ltd</p>
                    <li>Attractive rate of interest </li><p>Interest will be charged only for the amount utilised</p>
                    <li>Quick disbursal</li> <p>The loan amount will be credited directly to the client’s bank <br> account through RTGS/NEFT</p>
                    <li>Maximum % of funding</li> <p>Funding up to 50% of the value of pledged shares</p>
                    <li>Flexible repayment option </li><p>Client can settle the loan either by payment or by<br> selling the pledged shares</p>
                    <li>No postdated cheques</li><p></p>
                    <li>No prepayment charges</li><p></p>
                    </ul>
                </div>
            <div class="col-md-1 content-gd-wthree">
            </div>
            <div class="col-md-5 content-gd-wthree d-flex align-items-center" style="justify-content: center;" >
                <img src="assets/section1.svg" class="img-fluid z-idex-geojit-landing image-desktop" alt="" />
                <img src="assets/section1mobile.svg" class="img-fluid z-idex-geojit-landing image-mobile" alt="" />
            </div>    
        </div>
    </div>
</div>
</section>

<section class="banner-bottom py-5  section-secondback-geojit-landing">
    <div class="container py-md-4">
        <div class="row text-rotate-geojit-landing">
            <div class="col-md-5 content-gd-wthree d-flex align-items-center"style="justify-content: center;" >
                <img src="assets/eligibility.svg" class="img-fluid image-desktop" alt=""  />
                <img src="assets/eligibilitymobile.svg" class="img-fluid image-mobile" alt=""  />

            </div>
            <div class="col-md-1 content-gd-wthree">
            </div>
            <div class="col-md-6 content-second-geojit-landing ab-contents " style="place-self: center;">
                <div class="col"><h4>Eligibility</h4></div>
                <ul>
                    <li>The client should have a trading and<br> depository account with Geojit</li><p></p>
                    <li>They should have free holdings of eligible shares in <br>their DP account</li><p></p>
                    <li>  Valid proof of ID and proof of address is essential</li><p></p>
                    <li>   They should also have a satisfactory CIBIL score</li><p></p>
                    </ul>
            </div>
        </div>
    </div>
</section>
<div class="container">
    <div class="row">
        <div class="col-sm">
            <h6 class="text-topcard-geojit-landing">How it Works?</h6>
        </div>
    </div>
  <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-5">
    <div class="col mb-4">
      <div class="card card-geojit-home border-0">
        <img src="assets/home/check-eligible.svg" class="card-img-top" alt="..." style="width: 102px;
        height: 92px;">
        <div class="card-body">
          <h5 class="card-title">Check eligibility &<br>select scrips</h5>
        </div>
      </div>
    </div>
    <div class="col mb-4">
      <div class="card card-geojit-home border-0">
        <img src="assets/home/select-scheme.svg" class="card-img-top" alt="..." style="width: 136px;
        height: 78px; margin-top: 13px;">
        <div class="card-body">
          <h5 class="card-title">Select the scheme<br>and apply</h5>
        </div>
      </div>
    </div>
    <div class="col mb-4">
        <div class="card card-geojit-home border-0">
          <img src="assets/home/digital-sign.svg" class="card-img-top" alt="..." style="width: 111px;
          height: 86px; margin-top: 7px;">
          <div class="card-body">
            <h5 class="card-title">Accept T&C<br>with digital signing</h5>
          </div>
        </div>
      </div>
    <div class="col mb-4">
      <div class="card card-geojit-home border-0">
        <img src="assets/home/pledge.svg" class="card-img-top" alt="..." style="width: 117px;
        height: 74px; margin-top: 23px;">
        <div class="card-body">
          <h5 class="card-title">Pledge the shares<br>from your DP</h5>
        </div>
      </div>
    </div>
    <div class="col mb-4">
      <div class="card card-geojit-home border-0">
        <img src="assets/home/verification.svg" class="card-img-top" alt="..." style="width: 118px;
        height: 79px; margin-top: 17px;">
        <div class="card-body">
          <h5 class="card-title">Verification approval<br>& disbursal of amount</h5>
        </div>
      </div>
    </div>
    
    </div>
    <div class="row">
        <div class="col-sm" style="text-align: center;">
            <button class="btn-applyloan-geojit-landing">Apply for loan</button>
        </div>
    </div>
  </div>
    <div class="footer-grid-geojit-landing" >
        <div class="container py-md-5">
            <img src="assets/geojit-logo.svg" class="header-logo-geogit-home" >
            <div class="row ">  
                <div class="col-lg-3 footer_wthree_gridf mt-md-0 mt-4 pull-left">
                    <ul class="footer_wthree_gridf_list" style="padding: 0px 0px;">
                        <li>
                            <a  style="font-weight:600 ;cursor: text;"> Address</a>
                        </li>
                        <li>
                            <a style="cursor: text;"> 34/659-P, Civil Line Road Padivattom ,<br> Kochi, Kerala 682024</a>
                        </li>  
                    </ul>
                </div>
                <div class="col-lg-3 footer_wthree_gridf mt-md-0 mt-sm-4 ">
                    <ul class="footer_wthree_gridf_list" style="padding: 0px 0px;">
                        <li>
                            <a   style="font-weight:600; cursor: text;">Email</a>
                        </li>
                        <li class="hover-class">
                            <span>customercare@geojitcredits.com</span><br>
                            <span>grievances@geojitcredits.com </span>
                        </li>
                    
                        
                    </ul>
                </div>
                <div class="col-lg-3 footer_wthree_gridf mt-md-0 mt-sm-4 ">
                    <ul class="footer_wthree_gridf_list" style="padding: 0px 0px;">
                        <li>
                            <a   style="font-weight:600; cursor: text;"> Phone </a>
                        </li>
                        <li class="hover-class">
                          <span style="display: inline;"> 0484-2901252, </span> <span>2901255</span>
                        </li>
                    </ul>
                </div>
                <div class="col-lg-3 footer_wthree_gridf mt-md-0 mt-sm-4 ">
                    <ul class="footer_wthree_gridf_list" style="padding: 0px 0px;">
                        <li>
                            <a  style="font-weight:600; cursor: text;">Follow Us </a>
                        </li>
                        <li>
                            <a ><span class="fa fa-facebook"></span><span class="fa fa-twitter"></span><span class="fa fa-linkedin"></span></a>
                            <a > </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="row ">  
                <div class="col-lg-11 footer_wthree_gridf mt-md-0 mt-4 pull-left" >
                    
                    <p class="disclaimer-text-geojit-landing">Disclaimer - Loan Against Shares (LAS ) is provided by Geojit Credits Private Limited. RBI Registration No B-16.00123.<br>
                        Website:<a  class="website-link-geojit-landing" href="http://www.geojitcredits.com">www.geojitcredits.com</a>, CIN – U65910KL1991PTC006106.</p>
                    
                </div>
                <div class="col-lg-11 footer_wthree_gridf mt-md-0 mt-2 pull-left" >
                    <ul class="footer_wthree_gridf_list" style="padding: 0px 0px;">
                    <li>
                    <p style="    font-size: 16px;
                    letter-spacing: 0.05px;
                    color: #000000;">* Disclosure: These are not Exchange traded products and the Member is just acting as distributor. All disputes with respect to the distribution activity would not have access to Exchange investor redressal forum or Arbitration mechanism.</p>
                    </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    
    <div class="cpy-right-geojit-landing  py-3 ">
            <div class="container">
                <div class="row">
                <div class="col-lg-6 terms-cond-geojit-landing ">  All rights reserved <a class="space-mobile-geojit-landing">| </a> <a class="terms-cond-geojit-landing"> Terms & Conditions <br></a> <a class="hidden-mobile-geojit-landing ml-3 mr-3">|</a><a class="terms-cond-geojit-landing"> FAQ </a><a class="space-mobile-geojit-landing">|</a> <a class="terms-cond-geojit-landing"> Privacy Policy</a></div>
                <div class="col-lg-5 cpy-text-geojit-landing"> <a> Designed by : webandcrafts.com</a></div>
                </div>
            </div>   
    </div>
</div>
