    <div class="container-fluid" style="height: 830px;">
      <div class="row toalign-center-geojit-signup ">
          <div class="col-lg-6">
              <div class="card1">
                  <div class="row  justify-content-center border-line wall-image-geogit-login"> <img src="assets/wall.svg" class="image"> </div>
              </div>
              <div class="centered-geogit-login">
                <div class="row left-geogit-login">
                    <img src="assets/geojit-logo.svg" title="Geojit-Credits" [routerLink]="['/']" alt="Geojit-Credits" class="logo-geogit-login" style="cursor: pointer;">
                    <h2 class="head-text-geogit-login" >Avail Loan Against<br>Shares online,<br> instantly.</h2>
                    <img src="assets/login-bottom.svg" class="bottom-image-geogit-login" alt="Avatar woman">
                 </div>
               </div>
          </div>
          <div class="col-lg-5 box-center-geogit-login" style="max-width: 500px;">
              <div class="card2-geogit-login card border-0 ">
                 <form [formGroup]="loginForm"  (ngSubmit)="onSubmit()">
                    <div class="row col-sm-11">
                      <h1 class="text-top-geogit-login">Login</h1>

                      </div>
                  <div class="row col-sm-11"> 
                      
                    <label class="mb-1">
                          <h6 class="mb-0 text-sm-geogit-login">EMAIL</h6>
                      </label> <img *ngIf="!f.email.errors" class="tick-icon-geogit-login" src="assets/email-tick.svg"  /><input (change)="change($event)" formControlName="email" class="mb-4 input-box-geojit-login" type="text" name="email" placeholder="Enter a valid email address" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" > 
                      <!-- <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                        <div *ngIf="f.email.errors.required">Email is required</div>
                        <div *ngIf="f.email.errors.email">Email must be a valid email address</div>
                </div> -->
                    </div>
                  <div class="row col-sm-11"> <label class="mb-1">
                          <h6 class="mb-0 text-sm-geogit-login">PIN</h6>
                      </label><img *ngIf="passview" class="image-icon-geogit-login" (mouseout)="passview=false" src="assets/eye-ic.svg"> <img *ngIf="!passview" class="image-icon-geogit-login" src="assets/red-eye.svg" (mouseover)="passview=true" (mouseout)="passview=false" /><input formControlName="password"  [type]="passview ? 'number' : 'password'"  id="myPassword" name="password" placeholder="Enter PIN" class="input-box-geojit-login">
                    </div>
              <div class="row col-sm-11 mt-4">
                      <div class="custom-control custom-checkbox custom-control-inline"> <input id="chk1" type="checkbox" name="chk" class="custom-control-input"></div> <a class="ml-auto mb-0 text-sm-geogit-login" data-toggle="modal" data-target="#myModal" >Forgot your pin ?</a>
                  </div>
                  <div class="row  col-sm-11 mt-4"> <button type="submit"  class=" btn-blue-geogit-login text-center-geogit-login" [routerLink]="['/dash/home']" (click)="login()">Login</button> </div>
                  <div  class="row col-sm-11 register-route-geogit-login mt-4 mb-4"> <small class="font-weight-geogit-login">Don't have an account? <a class="text-register-geogit-login" [routerLink]="['/signup']">Signup</a></small> </div>
                </form>   
              </div>
          </div>
      </div>
</div>
<div class="modal " id="myModal" aria-hidden="true">
  <div class="modal-dialog modal-forgot-geojit-login">
    <div class="modal-content modal-forgot-geojit-login" >
     
      <div *ngIf="!OTPScreen && !NewPinScreen" class="modal-body">
        <!-- screen 1 modal forgot pin -->
        <form  [formGroup]="loginForm"  class="popup-forget-geogit-login">
            <div class="row col-sm d-flex justify-content-center">
                <h6 class="Forgot-modal-geogit-login">Forgot PIN</h6>
                <!-- <img src="assets/geojit-logo.svg" class="logo-geojit-forgot-password" > -->
              </div>
            <div class="row col-sm-11"> <label class="mb-1">
                    <h6 class="mb-1 text-sm-geogit-login">MOBILE NUMBER</h6>
                </label> <input (change)="change($event)" formControlName="username" class="mb-4 input-box-geojit-login" pattern="[1-9]" type="text" name="email" placeholder="Enter Mobile Number"  >
            </div>      
            <div class="row  col-sm-11 "> <button type="submit" class=" btn-blue-geogit-login text-center-geogit-login mt-3" (click)="forget()">Generate OTP</button> </div>
            <!-- <div  class="row col-sm-6 register-route-geogit-login mt-4"> <small class="font-weight-geogit-login"><a class="text-register-geogit-login" data-dismiss="modal">Back to Login</a></small> </div> -->
          </form> 
      </div>  

      <div  *ngIf="OTPScreen" class="modal-body " style="height: 367px;">
        <!-- screen 2 modal forgot pin -->
        <form  [formGroup]="loginForm"  class="popup-forget-geogit-login">
          <div class="row col-sm d-flex justify-content-center">
              <h6 class="Forgot-modal-geogit-login">Forgot PIN</h6>
              <!-- <img src="assets/geojit-logo.svg" class="logo-geojit-forgot-password" > -->
            </div>
          <div class="row col-sm-11"> <label class="mb-1">
                  <h6 class="mb-1 text-sm-geogit-login">MOBILE OTP</h6>
              </label> <input (change)="change($event)" formControlName="username" class="mb-4 input-box-geojit-login" type="number" name="email" placeholder="Enter OTP"  >
          </div>      
          <div class="row  col-sm-11 "> <button type="submit" class=" btn-blue-geogit-login text-center-geogit-login mt-3" (click)="VerifyOTP()">Verify OTP</button> </div>
          <div  class="row col-sm-11 register-route-geogit-login mt-4"> <small class="font-weight-geogit-login">Didn't receive code ? <a class="text-register-geogit-login" data-dismiss="modal">Resend OTP</a></small> </div>
        </form> 
          </div>
        <!-- screen 3 modal forgot pin -->
        <div *ngIf="NewPinScreen" class="modal-body" style="height: 257px;">
          <form  [formGroup]="loginForm"  class="popup-forget-geogit-login">
            <div class="row col-sm d-flex justify-content-center">
              <h6 class="Forgot-modal-geogit-login mt-2" style="padding-bottom: 19px;">Forgot PIN</h6>
            </div>
            <div class="row col-sm d-flex justify-content-center"> <label >
                    <h6 class="mb-0 text-sm-geogit-login">Click here to generate New PIN</h6>
                </label> 
            </div>
            <div class="row  col-sm-11 mt-3 "> <button type="submit" class=" btn-blue-geogit-login text-center-geogit-login" >Generate OTP</button> </div>
          </form> 
      
      </div>
     
    </div>
  </div>
</div>              