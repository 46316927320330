<div class="container-fluid" style="height: 830px;">
    <div class="row toalign-center-geojit-signup">
        <div class="col-lg-6">
            <div class="card1">
                <div class="row  justify-content-center border-line wall-image-geogit-login"> <img src="assets/wall.svg" class="image"> </div>
            </div>
            <div class="centered-geogit-login">
              <div class="row left-geogit-login">
                  <img src="assets/geojit-logo.svg" title="Geojit-Credits" [routerLink]="['/']" alt="Geojit-Credits" class="logo-geogit-login" style="cursor: pointer;">
                  <h2 class="head-text-geogit-login" >Avail Loan Against<br>Shares online,<br> instantly.</h2>
                  <img src="assets/login-bottom.svg" class="bottom-image-geogit-login" alt="Avatar woman">
               </div>
             </div>
        </div>

        <div class="col-lg-5 box-center-geogit-signup" style="max-width: 500px;     place-self: flex-end;max-height: 599px;">

        <!-- <div class="row background-image-geojit-signup" style="background-image: url('assets/Line.svg')" >

            <div class="col"> <img src="assets/user2.svg" class="progress-icons-gejit-signup" (click)="progress1()"><div class="progress-caption-geojit-signup">User Registration</div></div>
            <div class="col" *ngIf="!MobileAuth && !EmailVerBoxshow && !CreatePinBoxshow && !DoneBoxshow" (click)="progress2()"> <img src="assets/p2.svg" class="progress-icons-gejit-signup"><div class="progress-caption-geojit-signup">Mobile Verification</div></div>
            <div class="col" *ngIf="MobileAuth || EmailVerBoxshow || CreatePinBoxshow || DoneBoxshow" (click)="progress2()"> <img src="assets/p1.svg" class="progress-icons-gejit-signup"><div class="progress-caption-geojit-signup">Mobile Verification</div></div>

            <div class="col" *ngIf="!EmailVerBoxshow && !CreatePinBoxshow && !DoneBoxshow" (click)="progress3()"> <img src="assets/q2.svg" class="progress-icons-gejit-signup"><div class="progress-caption-geojit-signup">Email Verification</div></div>
            <div class="col" *ngIf="EmailVerBoxshow || CreatePinBoxshow || DoneBoxshow" (click)="progress3()"> <img src="assets/q1.svg" class="progress-icons-gejit-signup"><div class="progress-caption-geojit-signup">Email Verification</div></div>

            <div class="col" *ngIf="!CreatePinBoxshow && !DoneBoxshow"> <img src="assets/r2.svg" class="progress-icons-gejit-signup"><div class="progress-caption-geojit-signup">Create PIN</div></div>
            <div class="col" *ngIf="CreatePinBoxshow || DoneBoxshow"> <img src="assets/r1.svg" class="progress-icons-gejit-signup"><div class="progress-caption-geojit-signup">Create PIN</div></div>

            <div class="col" *ngIf="!DoneBoxshow"> <img src="assets/s2.svg" class="progress-icons-gejit-signup"><div class="progress-caption-geojit-signup">Done</div></div>
            <div class="col"  *ngIf="DoneBoxshow"> <img src="assets/s1.svg" class="progress-icons-gejit-signup"><div class="progress-caption-geojit-signup">Done</div></div>

        </div> -->

            <div class="card2-geogit-login card border-0 container-fluid2-geogit-login padding-top-geojit-signup">
                <div class="row px-3">
                </div>
                       
                  <!-- signup tab -->

                      <form  [formGroup]="loginForm"  >
                        <div class="row col-sm-11">
                            <h6  *ngIf="EmailAuth && MobileAuth" class="text-top-geogit-login">Signup</h6>
                            <h6  *ngIf="!EmailAuth || !MobileAuth" class="text-top-geogit-login" style="margin-top: 72px;">Signup</h6>
                            </div>
                      <div class="row col-sm-11"> <label class="mb-1">
                              <h6 class="mb-0 text-sm-geogit-login">NAME</h6>
                          </label> <input  class="mb-4 input-box-geojit-login" type="text" name="email" placeholder="Enter Name" > </div>
                      <div class="row col-sm-11"> <label class="mb-1">
                          <h6 class="mb-0 text-sm-geogit-login">EMAIL</h6>
                          </label> <a class="image-icon-geogit-signup" (click)="verifyEmail()">Verify</a>
                          <input  class="mb-4 input-box-geojit-login"    id="myPassword" name="password" placeholder="Enter Email">
                      </div>

                      <div *ngIf="EmailAuth" class="row col-sm-11 otpbox-mobile-geojit-signup"> 
                        <div class="row col-sm otp-mobile-geojit-signup">
                            <label for="verifyMobile" class="mb-1">
                            <h6 class="mb-0 text-sm-geogit-login">EMAIL OTP</h6>
                             </label>
                        <input  class="mb-4 input-box-geojit-login" type="number"   id="myPassword" name="password" placeholder="Enter OTP" style="width: 221px;">
                        </div>
                        <div class="row col-sm text-resendOTP-geojit-signup">
                            <label for="verifyMobile" class="mb-1"></label>
                             <small class="font-weight-geogit-login">Didn't receive code ? <a class="text-register-geogit-login" >Resend OTP</a></small> 
                        </div>
                    </div>

                      <div class="row col-sm-11"> <label class="mb-1">
                          <h6 class="mb-0 text-sm-geogit-login">MOBILE NUMBER</h6>
                      </label>
                      <a class="image-icon-geogit-signup" (click)="verifyMobile()">Verify</a>
                      <input name="password" type="number" placeholder="Enter Mobile Number" class="mb-4 input-box-geojit-login">
                      </div>

                      <div *ngIf="MobileAuth" class="row col-sm-11 otpbox-mobile-geojit-signup"> 
                        <div class="row col-sm  otp-mobile-geojit-signup">
                            <label for="verifyMobile" class="mb-1">
                            <h6 class="mb-0 text-sm-geogit-login">MOBILE OTP</h6>
                             </label>
                        <input  class="mb-4 input-box-geojit-login"  type="number"  id="myPassword" name="password" placeholder="Enter OTP" style="width: 221px;">
                        </div>
                        <div class="row col-sm text-resendOTP-geojit-signup">
                            <label for="verifyMobile" class="mb-1">
                             </label>
                             <small class="font-weight-geogit-login">Didn't receive code ? <a class="text-register-geogit-login" >Resend OTP</a></small> 
                        </div>
                    </div>
                    <div *ngIf="!MobileAuth && !EmailAuth" class="row col-sm-11"> 
                            <small class="font-weight-geogit-login">If you click on verify, You will receive an OTP to the <br> given Email id and mobile number</small> 
                    </div>

                      <div class="col-sm-11 mt-3">
                        
                        <!-- <div class="input-group">
                            <img  src="assets/Icon-reload.svg"  (click)="reloadCaptcha()" />
                            <div class="row  background-captcha-geojit-signup">
                            <input type="number" class="input2-captcha-geojit-signup" formControlName="firstNumber"  id="firstNumber" readonly> 
                                <a class="input3-captcha-geojit-signup">+</a>
                            <input type="number" class="input2-captcha-geojit-signup" formControlName="secondNumber"  id="secondNumber" readonly >
                                <a class="input3-captcha-geojit-signup">=</a>
                        </div>
                            <div class="col-sm ">
                                <input type="number" class=" input-box-geojit-login margin-captcha-geojit-signup" formControlName="answer" id="answer" placeholder="Captcha sum">
                            </div>
                          </div> -->

                                   
                                        <!-- <div class="form-group  col-sm">
                                            <input type="number" class=" input-box-geojit-login" formControlName="firstNumber"  id="firstNumber" readonly>
                                        </div>
                                            <div class="form-group col-sm">
                                                <input type="number" class=" input-box-geojit-login" formControlName="secondNumber" id="secondNumber" readonly>
                                        </div> -->
                                               
                                        <!-- <pre>
                                            {{loginForm.value|json}}
                                           {{loginForm.valid|json}}
                                            </pre> -->
                      </div>
                      <div *ngIf="EmailAuth && MobileAuth" class="row  col-sm-11 mt-4"> <button type="submit" class=" btn-blue-geogit-login text-center-geogit-login" (click)="signup()" [routerLink]="['/create-pin']" >Continue</button> </div>
                      <div *ngIf="!EmailAuth || !MobileAuth" class="row  col-sm-11 mt-4"> <button type="submit" class="btn-disable-geojit-signup btn-blue-geogit-login  text-center-geogit-login" style="pointer-events: none;">Continue</button> </div>

                      <div [routerLink]="['/signup']" class="row col-sm-10 mt-3 register-route-geogit-login mb-4"> <small class="font-weight-geogit-login">Already have an account? <a class="text-register-geogit-login" [routerLink]="['/login']">Login</a></small> </div>
                    </form> 

                    


                  <!-- mobile verification -->

                    <!-- <form *ngIf="MobileAuth" [formGroup]="loginForm"  class="form-login-geogit-login ">
                        <div class="row col-sm-7 mr-3">
                            <h6  class="mb-0  mt-2 text-top-geogit-login">Mobile Verification</h6>
                        </div>
                         <div class="row col-sm-7 register-route-geogit-login mb-4 mr-3"> <small class="font-weight-geogit-login">We will send you a verification code to your phone number </small> </div>
                         <div class="row col-sm-8 mobile-verification-geojit-signup" > 
                       <div class="col-sm-7"> <input formControlName="password"    name="mobile" placeholder="Mobile number" class="input-box-geojit-login" value="{{showmobile}}"></div>
                       <div class="col-sm-5"><button type="submit" class=" btn-blue-geogit-login text-center-geogit-login" (click)="GenerateOtp()">Generate OTP</button></div>
                       </div>
                       <div *ngIf="Otpboxshow" class="row col-sm-8 mt-4 mobile-verification-geojit-signup"> 
                         <div class="col-sm-7"> <input formControlName="password"    name="password" placeholder="Enter OTP" class="input-box-geojit-login"></div>
                         <div class="col-sm-5"><button type="submit" class=" btn-blue-geogit-login text-center-geogit-login" (click)="VerifyOtp()" >Verify OTP</button></div>
                         </div>
                     </form> -->

                    <!-- email verification -->

                    <!-- <form *ngIf="EmailVerBoxshow" [formGroup]="loginForm"  class="form-login-geogit-login ">
                        <div class="row col-sm-7 mr-3">
                            <h6  class="mb-0  mt-2 text-top-geogit-login">Email Verification</h6>
                        </div>
                         <div class="row col-sm-7 register-route-geogit-login mb-4 mr-3"> <small class="font-weight-geogit-login">We will send you a verification code to your email Id</small> </div>
                         <div class="row col-sm-8 mobile-verification-geojit-signup" > 
                       <div class="col-sm-7"> <input formControlName="password"    name="mobile" placeholder="Email id" value="{{showmobile}}" class="input-box-geojit-login"></div>
                       <div class="col-sm-5"><button type="submit" class=" btn-blue-geogit-login text-center-geogit-login" (click)="GenerateOtp()">Generate OTP</button></div>
                       </div>
                       <div *ngIf="Otpboxshow" class="row col-sm-8 mt-4 mobile-verification-geojit-signup"> 
                         <div class="col-sm-7"> <input formControlName="password"    name="password" placeholder="Enter OTP" class="input-box-geojit-login"></div>
                         <div class="col-sm-5"><button type="submit" class=" btn-blue-geogit-login text-center-geogit-login" (click)="VerifyEmailOtp()">Verify OTP</button></div>
                         </div>
                     </form> -->

                      <!-- Create PIN -->
                    <!-- <form *ngIf="CreatePinBoxshow" [formGroup]="loginForm"  class="form-login-geogit-login ">
                        <div class="row col-sm-7">
                            <h6  class="mb-0  mt-2 text-top-geogit-login">Create PIN
                            </h6>
                        </div>
                         <div class="row col-sm-7  mb-4"> <small class="font-weight-geogit-login">Create your PIN For Login</small> </div>
                         <div class="row col-sm-7 mobile-verification-geojit-signup" > 
                        <input formControlName="password"    name="mobile" placeholder="Email id" value="{{showmobile}}" class="input-box-geojit-login">
                       </div>
                       <div  class="row col-sm-7 mt-4 "> 
                          <input formControlName="password"    name="password" placeholder="Enter OTP" class="input-box-geojit-login">
                          </div>
                         <div class="col-sm-7"><button type="submit" class=" btn-blue-geogit-login text-center-geogit-login createpin-btn-geojit-signup" (click)="Done()">Done</button></div>
                         
                     </form> -->
                       <!-- Done -->
                    <!-- <form *ngIf="DoneBoxshow" [formGroup]="loginForm"  class="form-login-geogit-login ">
                        <div class="row col-sm-7 place-content-geojit-signup ">
                            <img src="assets/done.svg" class="place-margin-geojit-signup">
                        </div>
                        <div class="row col-sm-7 place-content-geojit-signup">
                            <h2  class="mb-0  mt-2 text-success-geogit-signup">Success </h2>
                        </div>
                         <div class="row col-sm-7  mb-4"> <small class="font-weight-geogit-login">Your account has been created, Successfully.</small> </div>
                         
                         <div class="col-sm-7"><button type="submit" class=" btn-blue-geogit-login text-center-geogit-login " >Continue</button></div>
                         
                     </form> -->

            </div>
        </div>
    </div>
</div>
