import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './views/user/login/login.component';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { SignupComponent } from './views/user/signup/signup.component';
import {MatTabsModule} from '@angular/material/tabs'
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { HomePageComponent } from './views/dashboard/home-page/home-page.component';
import { DashboardLayoutComponent } from './views/dashboard/dashboard-layout/dashboard-layout.component';
// import { HomeComponent } from './views/home/pages/home/home.component';
import { RouterModule } from '@angular/router';
import { CreatePinComponent } from './views/user/create-pin/create-pin.component';
import { HeaderComponent } from './shared/header/header.component';
import { SidebarComponent } from './shared/sidebar/sidebar.component';
// import { LasEligibleSecurityComponent } from './views/dashboard/pages/las-eligible-security/las-eligible-security.component';
// import { LoanApplicationComponent } from './views/dashboard/pages/loan-application/loan-application.component';
// import { HelpAndsecurityComponent } from './views/dashboard/pages/help-andsecurity/help-andsecurity.component';
// import { LoanDetailsComponent } from './views/dashboard/pages/loan-details/loan-details.component';
// import { MyActivitiesComponent } from './views/dashboard/pages/my-activities/my-activities.component';
// import { UserProfileComponent } from './views/dashboard/pages/user-profile/user-profile.component';
// import { AccountSummaryComponent } from './views/home/pages/account-summary/account-summary.component';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';
import { MatChipsModule } from '@angular/material/chips';
// import { StylePaginatorDirective } from './views/dashboard/pages/las-eligible-security/style-paginator.directive';

import { PdfViewerModule, LinkAnnotationService, BookmarkViewService, MagnificationService, ThumbnailViewService,
  ToolbarService, NavigationService, TextSearchService, TextSelectionService, PrintService } from '@syncfusion/ej2-angular-pdfviewer';


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    SignupComponent,
    HomePageComponent,
    DashboardLayoutComponent,
    CreatePinComponent,
    HeaderComponent,
    SidebarComponent,
    // StylePaginatorDirective,
    // LasEligibleSecurityComponent,
    // LoanApplicationComponent,
    // HelpAndsecurityComponent,
    // LoanDetailsComponent,
    // MyActivitiesComponent,
    // UserProfileComponent,
    // AccountSummaryComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    MatTabsModule,
    BrowserAnimationsModule,
    RouterModule,
    MatTableModule,
    MatPaginatorModule,
    

    
  ],
  providers: [LinkAnnotationService, BookmarkViewService, MagnificationService,
    ThumbnailViewService, ToolbarService, NavigationService, TextSearchService, TextSelectionService, PrintService],
  bootstrap: [AppComponent]
})
export class AppModule { }
