<nav class="navbar navbar-expand navbar-light bg-header-geojit-dashboard ">
  <div id="content" class="display-sidebar-geojit-sidebar">
    <nav class=" navbar-expand-lg navbar-light">
        <div class="container-fluid">

            <button  type="button" id="sidebarCollapse" (click)="onActivate($event)" class="btn" style="z-index: 3;color: #61c5b1;">
              <i class="fa fa-bars" ></i>
            </button>
            <!-- <button class="btn btn-dark d-inline-block d-lg-none ml-auto" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="true" aria-label="Toggle navigation">
                <i class="fas fa-align-justify"></i>
            </button> -->
        </div>
    </nav>
</div>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
  
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav mr-auto header-logo-geojit-dashboard">
        <li class="nav-item active">
          <img src="assets/geojit-logo.svg"  title="Geojit-Credits" [routerLink]="['/']" alt="Geojit-Credits" class="header-image-geojit-dashboard" style="cursor: pointer;" />
        </li> 
      </ul>
      <form class="form-inline my-2 my-lg-0 dropdown">
        <img src="assets/notification.svg"   class="notification-image-geojit-profile" style="    cursor: pointer;"/>
        <img src="assets/Bell.svg"  class="mr-4" style="    cursor: pointer;" />
        <div class="dropdown-content notification-bar-geojit-header" >
          <ul style="color: #4d505f;">
            <li style="margin-top: 8px;">Notification 1</li>
            <li style="margin-top: 8px;">Notification 2</li>
            <li style="margin-top: 8px;">Notification 3</li>
          </ul>  
          </div>
      </form>
        <img src="assets/Users-Avatar.png"  class="user-image-geojit-dashboard " />
        <!-- <button class="btn btn-outline-success my-2 my-sm-0" type="submit">Search</button> -->
        <a class="header-text-geojit-dashboard hidein-mobile-geojit-header " >Anain Dev </a>
        <form class="form-inline my-2 my-lg-0 dropdown">
        <img src="assets/Icons-chevron-down.svg "  style="cursor: pointer;"/>
        <div class="dropdown-content" style=" padding: 22px 50px 20px 50px;">
            <a class="logout-text-geojit-header"  [routerLink]="['/login']" >Logout</a>
          </div>
        </form>
       
    </div>
  </nav>