import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery'
import { Router } from '@angular/router';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  name = 'Angular 6';
  tab : any = 'tab1';
  tab1 : any
  tab2 : any
  tab3 : any
  tab4 : any
  tab5 : any
  tab6 : any
hide:boolean=false;
  public href: string = "";
  Clicked : boolean=false;
  constructor(private router: Router) {
    console.log(window.location.href);
    this.href = this.router.url;
        console.log(this.router.url);
        this.onClick(this.router.url);
    // var mybutton = document.getElementById("myBtn");

    // // When the user scrolls down 20px from the top of the document, show the button
    // window.onscroll = function() {scrollFunction()};
    
    // function scrollFunction() {
    //   if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
       
    //   } 
    // }
    
    // // When the user clicks on the button, scroll to the top of the document
    // function topFunction() {
    //   document.body.scrollTop = 0;
    //   document.documentElement.scrollTop = 0;
    // }



   }
  
  ngOnInit(): void {
    $(document).ready(function () {
      $('#sidebarCollapse').on('click', function () {
          $('#sidebar').toggleClass('active');
      });
  });
  
//   function HeaderController($scope:any, $location:any) {
//     $scope.isActive = function (viewLocation:any) {
//         return viewLocation === $location.path();
//     };
// }

  
  }
  // showsidebar()
  // {
    
  // }
  onClick(check:any){
        if(check=='/dash/home'){
          this.tab = 'tab1';
          if (window.screen.width <= 991) {
          $('#sidebar').toggleClass('active');
          }
        }else if ( (check=='/dash/account') || (check=='/dash/account/loan-details')){
          this.tab = 'tab2';
          if (window.screen.width <= 991) {
            $('#sidebar').toggleClass('active');
            }

        }else if(check=='/dash/my-activity'){
          this.tab = 'tab3';
          if (window.screen.width <= 991) {
            $('#sidebar').toggleClass('active');
            }

        }
        else if(check=='/dash/profile'){
          this.tab = 'tab4';
          if (window.screen.width <= 991) {
            $('#sidebar').toggleClass('active');
            }
          
        }
        else if(check=='/dash/help-security'){
          this.tab = 'tab5';
          if (window.screen.width <= 991) {
            $('#sidebar').toggleClass('active');
            }

        }   
        else if(check=='/dash/las'){
          this.tab = 'tab6';
          if (window.screen.width <= 991) {
            $('#sidebar').toggleClass('active');
            }

        }       
      
    }

    onActivate(event:any) {
      console.log('hi');
      let scrollToTop = window.setInterval(() => {
          let pos = window.pageYOffset;
          if (pos > 0) {
              window.scrollTo(0, pos - 20); // how far to scroll on each step
          } else {
              window.clearInterval(scrollToTop);
          }
      }, 16);
  }
  hideSidebar()
  {
    this.hide=true;
  }

}
