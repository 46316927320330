
<app-header></app-header>

<div class="wrapper" style="padding-top: 68px;">
    <div class="sidebar" data-color="white" data-active-color="danger">
        <app-sidebar></app-sidebar>
    </div>
    <div class="main-panel-geojit-layout">
        <div class="content">
            <router-outlet></router-outlet>
            
        </div>
    </div>
</div>








  <div id="open-modal"  class="modal fade show" style="padding-right: 3px;padding-left: 3px;"> 
    <div class="modal-dialog" style="text-align: -webkit-center;max-width: 1038px">
        <div class="modal-content" >

        <div class="modal-body  loan-scheme-geojit-application" >
            <div class="row mt-2">
              <div class=" col-sm-6 d-flex justify-content-flex-start">
                <h6 class="Forgot-modal-geogit-login " style="padding-bottom: 19px;">Scheme Details</h6>
              </div>
              <div class=" col-sm-6" style="text-align: right;"> <label >
                      <img    type="button" class="close close-mobile-geojit-application" data-dismiss="modal" aria-hidden="true" src="assets/loan-application/icon-close.svg">
                  </label> 
              </div>
            </div>
              
              <table cellspacing="0" rules="all" border="1" id="Table1"  class="loanscheme-modal-geojit-dashboard">
                <tr style="height: 33px;">
                    
                    <th >Particulars</th>
                    <th style="color: #51337F;">Premium Scheme</th>
                    <th style="color: #0F887C;">Regular Scheme</th>
                    <th style="color: #D98500;">Festive Scheme</th>
                </tr>
                <tr>
                    
                    <td >Minimum Amount</td>
                    <td>Rs. 10,00,000 (Ten lakh)</td>
                    <td>Rs. 50,000 (Fifty Thousand)</td>
                    <td >Rs. 5,000,00 (Five lakh)</td>
                </tr>
                <tr>
                    
                    <td>Interest*</td>
                    <td>11% p.a.</td>
                    <td>12% p.a.</td>
            
                    <td >10% pa* (Subject to prompt payment of monthly Interest)</td>
                </tr>
                <tr>
                   
                    <td>Processing/Annual Review Charges.</td>
                    <td>1% of loan amount + GST Upfront annually</td>
                    <td>1% of loan amount + GST Upfront annually</td>
                    
                    <td >1% of loan amount + GST Upfront annually</td>
                </tr>
                <tr>
                    
                    <td>Margin</td>
                    <td>As stipulated in sanction Letter (60%)</td>
                    <td>As stipulated in sanction Letter (60%)</td>
                    
                    <td >As stipulated in sanction Letter (60%)</td>
                </tr>
                <tr>
                   
                    <td>Validity</td>
                    <td>1 Year, subject to annual review</td>
                    <td>1 Year, subject to annual review</td>
                    <td >6 Months</td>
                </tr>
                <tr>
                   
                    <td>Nature of securities Accepted as collateral</td>
                    <td>Group 1 shares as per approved list of the company</td>
                    <td>Group 1 shares as per approved list of the company</td>
                    <td >Group 1 shares as per approved list of the company</td>
                </tr>
                <tr>
                   
                    <td>Number of scrip</td>
                    <td>Minimum 3 scrips</td>
                    <td>Minimum 3 scrips</td>
                    <td >Minimum 3 scrips</td>
                </tr>
                <tr>
                   
                    <td>Exposure on single Scrip</td>
                    <td>Not exceeding 40% of total security value</td>
                    <td>Not exceeding 40% of total security value</td>
                    <td >Not exceeding 40% of total security value</td>
                </tr>
                <tr>
                   
                    <td>Interest Payment</td>
                    <td>First working day of every month after availing the loan.</td>
                    <td>First working day of every month after availing the loan.</td>
                    <td >First working day of every month after availing the loan.</td>
                </tr>
                <tr>
                   
                    <td>Documentation Charges</td>
                    <td>Rs.450</td>
                    <td>Rs.450</td>
                    <td >Rs.650</td>
                </tr>
                <tr>
                   
                    <td>Cheque Bounce Charges</td>
                    <td>Rs.300</td>
                    <td>Rs.300</td>
                    <td >Rs.300</td>
                </tr>
               
        
                
            </table>
        
      </div>  
  </div>
  </div>
  </div>


  <div id="open-success"  class="modal margin-modal-deojit-application" style="padding-right: 0px;text-align: -webkit-center;text-align: -moz-center;"> 
    <div class="modal-dialog " style="      height: 100%; top: 15%; max-width: 472px;">
        <div class="modal-content" >

        <div class="modal-body" style="text-align: -webkit-center;width: auto;
        height: 396px;">
            

            <form    class="form-login-geogit-login ">
                <div class="row col-sm-11 place-content-geojit-signup " style="    padding-top: 62px;">
                    <img src="assets/done.svg" class="place-margin-geojit-signup">
                </div>
                <div class="row col-sm-11 place-content-geojit-signup">
                    <h2  class="mb-0  mt-4" style="letter-spacing: 0.66px;color: #001745;opacity: 1;font-weight: bold;font-size: 22px;">Success</h2>
                </div>
                 <div class="row col-sm-11  mb-4 text-success-geogit-signup mt-4" style="justify-content: center;"> <small class="font-weight-geogit-login">Digitally Signed Successfully</small> </div>
                 <div class="row  col-sm-11 mt-4" style="justify-content: center; padding-top: 10px;"> <button type="submit" data-dismiss="modal" aria-hidden="true"  class=" btn-blue-geogit-login text-center-geogit-login" style="width: 162px;">Ok</button> </div>
                 
         </form>  

        </div>
              
              
        
      </div>  
  </div>
  </div>

          

  

